define("discourse/plugins/discourse-code-review/discourse/routes/admin-plugins-code-review", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsCodeReview extends _discourse.default {
    controllerName = "admin-plugins-code-review";
    activate() {
      this.controllerFor(this.controllerName).loadOrganizations();
    }
  }
  _exports.default = AdminPluginsCodeReview;
});