define("discourse/plugins/discourse-code-review/discourse/controllers/admin-plugins-code-review", ["exports", "@ember/array", "@ember/controller", "@ember/object", "rsvp", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators"], function (_exports, _array, _controller, _object, _rsvp, _ajax, _ajaxError, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const prefix = "/admin/plugins/code-review";
  class AdminPluginsCodeReviewController extends _controller.default {
    organizations = null;
    loading = true;
    async loadOrganizations() {
      try {
        let orgNames = await (0, _ajax.ajax)(`${prefix}/organizations.json`);
        this.set("organizations", (0, _array.A)([]));
        for (const orgName of orgNames) {
          let organization = _object.default.create({
            name: orgName,
            repos: (0, _array.A)([])
          });
          this.organizations.pushObject(organization);
        }
        await _rsvp.Promise.all(this.organizations.map(this.loadOrganizationRepos.bind(this)));
      } catch {
        this.set("organizationReposLoadFailed", true);
      } finally {
        this.set("loading", false);
      }
    }
    async loadOrganizationRepos(organization) {
      try {
        let repoNames = await (0, _ajax.ajax)(`${prefix}/organizations/${organization.name}/repos.json`);
        for (const repoName of repoNames) {
          let repo = _object.default.create({
            name: repoName,
            hasConfiguredWebhook: null,
            receivedWebhookState: false
          });
          organization.repos.pushObject(repo);
        }

        // No point continuing doing requests for the webhooks if there
        // is an error with the first request, the token permissions must be fixed first
        await this.hasConfiguredWebhook(organization.name, organization.repos[0]);
        await _rsvp.Promise.all(organization.repos.map(repo => this.hasConfiguredWebhook(organization.name, repo)));
      } catch (response) {
        this.set("loadError", true);
        (0, _ajaxError.popupAjaxError)(response);
      } finally {
        this.set("loading", false);
      }
    }
    async hasConfiguredWebhook(orgName, repo) {
      if (repo.receivedWebhookState) {
        return true;
      }
      let response = await (0, _ajax.ajax)(`${prefix}/organizations/${orgName}/repos/${repo.name}/has-configured-webhook.json`);
      repo.set("receivedWebhookState", true);
      repo.set("hasConfiguredWebhook", response["has_configured_webhook"]);
    }
    configureWebhooksTitle(loadError) {
      if (!loadError) {
        return "";
      }
      return "code_review.webhooks_load_error";
    }
    static #_ = (() => dt7948.n(this.prototype, "configureWebhooksTitle", [(0, _decorators.default)("loadError")]))();
    async configureWebhook(organization, repo) {
      if (repo.hasConfiguredWebhook === false) {
        let response = await (0, _ajax.ajax)(`${prefix}/organizations/${organization.name}/repos/${repo.name}/configure-webhook.json`, {
          type: "POST"
        });
        repo.set("hasConfiguredWebhook", response["has_configured_webhook"]);
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "configureWebhook", [_object.action]))();
    async configureWebhooks() {
      for (const organization of this.organizations) {
        for (const repo of organization.repos) {
          if (repo.hasConfiguredWebhook === false) {
            let response = await (0, _ajax.ajax)(`${prefix}/organizations/${organization.name}/repos/${repo.name}/configure-webhook.json`, {
              type: "POST"
            });
            repo.set("hasConfiguredWebhook", response["has_configured_webhook"]);
          }
        }
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "configureWebhooks", [_object.action]))();
  }
  _exports.default = AdminPluginsCodeReviewController;
});